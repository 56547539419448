import _str from 'underscore.string';
import React, { useState } from 'react';
import classNames from 'classnames';

import ConfirmationModal from 'components/utils/confirmation_modal';
import TippyTooltip from 'components/utils/tippy_tooltip';

export enum iconType {
  ADD,
  ATTACH,
  BACK,
  BOLD,
  BURGER_MENU,
  CARET_DOWN,
  CIRCLE,
  CLOSE,
  COLLAPSED_MENU,
  COPY,
  DELETE,
  DIVIDER,
  EDIT,
  ELLIPSIS,
  EXPORT,
  FILE_IMAGE,
  IMAGE,
  INFO,
  ITALIC,
  LINK,
  LIST_OL,
  LIST_UL,
  LOADING,
  LOCATION,
  MOVE_UP,
  MOVE_DOWN,
  NEW_LINE,
  PARAGRAPH,
  PREVIEW,
  QUOTE,
  REDO,
  REMOVE_FORMATTING,
  SEARCH,
  SHOPPING_BAG,
  SHOW,
  SEND,
  STRIKETHROUGH,
  TIME,
  UNDO,
  VIRTUAL,
  VERIFIED,
}

interface IconProps {
  type: iconType;
  classes?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  confirmationText?: string;
  tooltipText?: string;
}

// TODO: this Icon does too much, should probably create a ConfirmationIcon,
// ClickableIcon, ... this way 1) it'll simplify these classes, 2) make readability
// easier and 3) will allow us to have better type safety

export default function Icon({
  type,
  classes,
  onClick,
  isDisabled = false,
  confirmationText,
  tooltipText,
}: IconProps) {
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] =
    useState(false);

  const canHover: boolean = (onClick ?? false) && !isDisabled;
  let iconClassName: string;

  switch (type) {
    case iconType.ADD:
      iconClassName = 'fa fa-plus';
      break;
    case iconType.ATTACH:
      iconClassName = 'fas fa-paperclip';
      break;
    case iconType.BACK:
      iconClassName = 'fas fa-arrow-left';
      break;
    case iconType.BOLD:
      iconClassName = 'fas fa-bold';
      break;
    case iconType.BURGER_MENU:
      iconClassName = 'fas fa-bars';
      break;
    case iconType.CARET_DOWN:
      iconClassName = 'fas fa-caret-down';
      break;
    case iconType.CIRCLE:
      iconClassName = 'fas fa-circle';
      break;
    case iconType.CLOSE:
      iconClassName = 'fas fa-times';
      break;
    case iconType.COLLAPSED_MENU:
      iconClassName = 'fas fa-chevron-up';
      break;
    case iconType.COPY:
      iconClassName = 'far fa-copy';
      break;
    case iconType.DELETE:
      iconClassName = 'far fa-trash-alt';
      break;
    case iconType.DIVIDER:
      iconClassName = 'fas fa-minus';
      break;
    case iconType.EDIT:
      iconClassName = 'fas fa-pen';
      break;
    case iconType.ELLIPSIS:
      iconClassName = 'fas fa-ellipsis-h';
      break;
    case iconType.EXPORT:
      iconClassName = 'fas fa-download';
      break;
    case iconType.FILE_IMAGE:
      iconClassName = 'fas fa-file-image';
      break;
    case iconType.IMAGE:
      iconClassName = 'far fa-image';
      break;
    case iconType.INFO:
      iconClassName = 'fas fa-info-circle';
      break;
    case iconType.ITALIC:
      iconClassName = 'fas fa-italic';
      break;
    case iconType.LINK:
      iconClassName = 'fas fa-link';
      break;
    case iconType.LIST_OL:
      iconClassName = 'fas fa-list-ol';
      break;
    case iconType.LIST_UL:
      iconClassName = 'fas fa-list-ul';
      break;
    case iconType.LOCATION:
      iconClassName = 'fas fa-map-marker-alt';
      break;
    case iconType.LOADING:
      iconClassName = 'fas fa-circle-notch fa-spin';
      break;
    case iconType.MOVE_UP:
      iconClassName = 'fas fa-arrow-up';
      break;
    case iconType.MOVE_DOWN:
      iconClassName = 'fas fa-arrow-down';
      break;
    case iconType.NEW_LINE:
      iconClassName = 'fas fa-arrow-down';
      break;
    case iconType.PARAGRAPH:
      iconClassName = 'fas fa-paragraph';
      break;
    case iconType.PREVIEW:
      iconClassName = 'fas fa-eye';
      break;
    case iconType.QUOTE:
      iconClassName = 'fas fa-quote-left';
      break;
    case iconType.REDO:
      iconClassName = 'fas fa-redo';
      break;
    case iconType.REMOVE_FORMATTING:
      iconClassName = 'fas fa-remove-format';
      break;
    case iconType.SEARCH:
      iconClassName = 'fas fa-search';
      break;
    case iconType.SEND:
      iconClassName = 'far fa-paper-plane';
      break;
    case iconType.SHOW:
      iconClassName = 'fas fa-chevron-right';
      break;
    case iconType.SHOPPING_BAG:
      iconClassName = 'fas fa-shopping-bag';
      break;
    case iconType.STRIKETHROUGH:
      iconClassName = 'fas fa-strikethrough';
      break;
    case iconType.TIME:
      iconClassName = 'far fa-calendar';
      break;
    case iconType.UNDO:
      iconClassName = 'fas fa-undo';
      break;
    case iconType.VIRTUAL:
      iconClassName = 'fas fa-video';
      break;
    case iconType.VERIFIED:
      iconClassName = 'fas fa-check-circle';
      break;
    default:
      throw new Error(`unknown icon name '${type}'`);
  }

  const _onClick = (): void => {
    if (isDisabled) {
      // do nothing
    } else if (
      typeof confirmationText !== 'undefined' &&
      !_str.isBlank(confirmationText)
    ) {
      setShouldShowConfirmationModal(true);
    } else {
      onClick?.();
    }
  };

  const iconElement = (
    <i
      onClick={_onClick}
      className={classNames(
        'icon',
        iconClassName,
        { hoverable: canHover, disabled: isDisabled },
        classes,
      )}
    />
  );

  const element =
    typeof tooltipText === 'undefined' || _str.isBlank(tooltipText) ? (
      iconElement
    ) : (
      <TippyTooltip content={tooltipText}>{iconElement}</TippyTooltip>
    );

  return (
    <>
      {element}
      {shouldShowConfirmationModal &&
        typeof confirmationText !== 'undefined' &&
        typeof onClick !== 'undefined' && (
          <ConfirmationModal
            text={confirmationText}
            onCancel={() => setShouldShowConfirmationModal(false)}
            onConfirm={() => {
              onClick();
              setShouldShowConfirmationModal(false);
            }}
          />
        )}
    </>
  );
}
