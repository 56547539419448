import React from 'react';
import classNames from 'classnames';

import Helpers from 'helpers/helpers';
import EventTime from 'components/utils/event_time';
import EventLocationOrVirtual from 'components/utils/event_location_or_virtual';
import { MembershipProfilePic, picSize } from 'components/utils/profile_pic';
import MembersGroupPreview from 'components/utils/members_group_preview';

import { getEventState, EventStates } from 'models/event';
import { DateTimeZone, RawClubEvent } from 'types/types';
import {
  LoadingPlaceholder,
  placeholderType,
} from 'components/utils/placeholder';

// const i18nScope = 'components.utils.events.event_preview';

interface EventPreviewProps {
  currentTimeZone: DateTimeZone;
  rawClubEvent: RawClubEvent;
  openInNewTab?: boolean;
}

export function EventPreviewLoadingPlaceholder() {
  return (
    <div className="event-preview">
      <LoadingPlaceholder
        type={placeholderType.BAR}
        widthPercent="50%"
        classes="event-title mb-2 p-2"
      />

      <LoadingPlaceholder
        type={placeholderType.BAR}
        widthPercent="25%"
        classes="mb-2"
      />

      <LoadingPlaceholder
        type={placeholderType.BAR}
        widthPercent="20%"
        classes="mb-2"
      />
    </div>
  );
}

export default function EventPreview({
  currentTimeZone,
  rawClubEvent,
  openInNewTab = false,
}: EventPreviewProps) {
  const eventState = getEventState(rawClubEvent.attributes);

  const _onClick = () => {
    const url = rawClubEvent.links.clubEventPath;
    openInNewTab
      ? Helpers.Utils.openLinkInNewTab(url)
      : Helpers.Utils.redirectTo(url);
  };

  return (
    <div
      className={classNames('event-preview', `event-state-${eventState}`)}
      onClick={_onClick}
    >
      <h3 className="event-title">{rawClubEvent.attributes.name}</h3>
      <EventTime
        event={rawClubEvent.attributes}
        currentTimeZone={currentTimeZone}
      />
      <EventLocationOrVirtual
        event={rawClubEvent.attributes}
        shouldShowLocationLink={false}
      />
      {eventState !== EventStates.CANCELED && (
        <div className="event-attendees">
          <MembersGroupPreview
            members={rawClubEvent.attributes.attendeeProfilePicUrls}
            total={rawClubEvent.attributes.numberOfAttendees}
            renderMember={(attendeeProfilePicUrl, index) => (
              <MembershipProfilePic
                key={index}
                classes="inline-block"
                size={picSize.SMALL}
                profilePicUrl={attendeeProfilePicUrl}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}
